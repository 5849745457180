import React from "react";
import "assets/css/components/footer.scss";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "assets/images/branding/6.png";
import sky_logo from "assets/images/branding/sky_logo.png";
import { getContactData, getFooterInfo } from "utils/dataManager";

export const FooterBar = () => {
  const footerdata = getFooterInfo();

  return (
    <div className="footer_bg">
      <Container className="footer">
        <Row>
          <Col md="4" sm="4">
            <Row>
              <Col className="ml-auto mr-auto" md="7">
                <Image src={logo} fluid />
              </Col>
            </Row>
            <Row>
              <Col className="cr">{footerdata.copyright}</Col>
            </Row>
          </Col>

          <Col className="ml-auto info bu_info" md="2">
            <Row>
              <div>Mailing Address:</div>
              <div>{footerdata.office_location}</div>
            </Row>
            <Row>
              <div>Email:</div>
              <div>{footerdata.email}</div>
            </Row>
            {/* 
            <Row> Policy </Row>
            <Row> Legal </Row> */}
          </Col>

          <Col className="ml-auto info" md="4">
            <Row className="info_other">{footerdata.message}</Row>

            <Row className="info_legal"></Row>

            <Row className="info_sky">
              <p>
                Designed and developed by Sky Johnson with React JS Check out{" "}
                <a href="https://skyjohnson.me"> skyjohnson.me</a> if you're
                interested in a website
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
