export interface footerinfoType {
  office_location: string;
  email: string;
  message: string;
  copyright: string;
}

export const footerinfo: footerinfoType = {
  office_location: "9600 Escarpment Blvd STE 745 #62 Austin, Texas 78749",
  email: "info@precisionalliedservices.com",
  message:
    "PAS has been a long standing business since (2012) and is more than excited to help you and your company design and deploy advanced metering solutions. Please get in contact with us as soon as possible.",
  copyright: "© Copyright 2020. All Rights Reserved.",
};
