export interface serviceinfoType {
  title: string;
  subtitle: string;
  subtitle_description: string;
  field: {
    title: string;
    description: string;
    fields: string;
    fields2: string;
  };
  services_intro: string;
  services: string[];
}

export const serviceinfo: serviceinfoType = {
  title: "Services",
  subtitle: "What we do.",
  subtitle_description:
    "Our goal is to understand your unique business needs and to utilize our vast industry knowledge and expertise to develop the most effective solutions for your AMI plans while reducing costs and increasing efficiency for your organization.",
  field: {
    title: "Vast Knowledge and Experience",
    description:
      "Our team has over 40 years of combined diversified experience.  We pride ourselves on bringing unique and nimble ideas and solutions to our customers that drive results in markets such as:",
    fields: "Water Utilities, Gas Utilities, Electric Utilities & Liquid Natural Gas brokering",
    fields2: "",
  },
  services_intro: "Our services include but are not limited too:",
  services: [
    "Project Management",
    "Business Consulting",
    "3rd Party Integration",
    "Meter Deployment Implementation",
    "Liquid Natural Gas Sourcing and Brokerage",
    "Sub-Contractor Management",
    "Installation Services Training",
    "Meter Network Optimization",
    "Technical Installation Consulting",
    "Field Service Management and Mitigation",
    "Vendor Selection and Coordination",
    "Analytics Software Services",
    "Server Side Mitigation",
    "User Training",
    "Product Procurement",
    "Cost-Benefit Analysis",
  ],
};
