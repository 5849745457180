import { serviceinfoType } from "data/servicesinfo";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardGroup,
  CardDeck,
  Image,
  Carousel,
} from "react-bootstrap";

import gas from "assets/images/stockphotos/shutterstock/GasMeter.jpg";
import water from "assets/images/stockphotos/shutterstock/WaterMeter.jpg";
import electric from "assets/images/stockphotos/shutterstock/ElectricMeter.jpg";
import lng from "assets/images/stockphotos/shutterstock/LNGtanker.jpg";

export const ServiceExp = (servicepagedata: serviceinfoType) => {
  const [imgValue, setImageValue] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      imgValue < 3 ? setImageValue(imgValue + 1) : setImageValue(0);
    }, 5000);

    return () => clearInterval(timer);
  });

  const images = [gas, electric, water, lng];

  return (
    <>
      <div id="services" />
      <div className="ap_service_exp">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto services_desc" md="10">
              <Row>
                <h1 className="title">{servicepagedata.title}</h1>
              </Row>
              <Row>
                <div className="line"></div>
              </Row>
              <Row>
                <h2 className="title">{servicepagedata.subtitle}</h2>
              </Row>
              <Row>
                <p className="description">
                  {servicepagedata.subtitle_description}
                </p>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container fluid className="provide_cont">
          <Row className="provide">
            {/* Probably want to use Slick or something tbh */}
            {/* <Col style={aboutBackgroundStyle} md="6" className="provide_c" /> */}

            {/* Let's make it a carousel */}
            <Col md="4" className="ml-auto mr-2 provide_c">
              <Carousel>
                <Carousel.Item>
                  <Image src={gas} className="provide_img" />
                </Carousel.Item>

                <Carousel.Item>
                  <Image src={water} className="provide_img" />
                </Carousel.Item>

                <Carousel.Item>
                  <Image src={electric} className="provide_img" />
                </Carousel.Item>

                <Carousel.Item>
                  <Image src={lng} className="provide_img" />
                </Carousel.Item>
              </Carousel>
            </Col>

            <Col md="4" className="ml-2 mr-auto provide_col provide_c">
              <h2 className="title">{servicepagedata.field.title}</h2>
              <div className="line"></div>
              <p>
              {servicepagedata.field.description}
              </p>

              <h4>{servicepagedata.field.fields}</h4>
              {/* <h4>{servicepagedata.field.fields2}</h4> */}
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h3>{servicepagedata.services_intro}</h3>
            </Col>
          </Row>
          <Row className="services_group" xs={1} md={3}>
            <Col>
              {servicepagedata.services.map((v, i) => (
                <>{i % 3 == 0 ? <p>{v}</p> : null}</>
              ))}
            </Col>
            <Col>
              {servicepagedata.services.map((v, i) => (
                <>{i % 3 == 1 ? <p>{v}</p> : null}</>
              ))}
            </Col>
            <Col>
              {servicepagedata.services.map((v, i) => (
                <>{i % 3 == 2 ? <p>{v}</p> : null}</>
              ))}
            </Col>
            {/* <div style={{ columns: 3 }} className="services_group">
              {servicepagedata.services.map((v) => (
                <p>{v}</p>
              ))}
            </div> */}
          </Row>
        </Container>
      </div>
    </>
  );
};
