import { aboutinfo } from "data/aboutinfo";
import { contactinfo } from "data/contactinfo";
import { footerinfo } from "data/footerinfo";
import { homepageinfo } from "data/homepageinfo";
import { probleminfo } from "data/probleminfo";
import { projectsinfo } from "data/projectsinfo";
import { serviceinfo } from "data/servicesinfo";

export const getHomepageData = () => {
  return homepageinfo;
};

export const getServiceData = () => {
  return serviceinfo;
};

export const getAboutData = () => {
  return aboutinfo;
};

export const getProblemData = () => {
  return probleminfo;
};

export const getContactData = () => {
  return contactinfo;
};

export const getProjectsData = () => {
  return projectsinfo;
};

export const getFooterInfo = () => {
  return footerinfo;
}