import React from "react";
import "assets/css/App.scss";
import { HomePage } from "pages/HomePage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ContactPage } from "pages/ContactPage";
import { AboutPage } from "pages/AboutPage";
import { ServicesPage } from "pages/ServicesPage";
import { ErrorPage } from "pages/ErrorPage";
import { NavigationBar } from "components/Navs/NavigationBar";
import { FooterBar } from "components/Footer/FooterBar";

function App() {
  return (
    <div className="App">
      <NavigationBar />

      <Router>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomePage}
          />
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={AboutPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/services"}`}
            component={ServicesPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={ContactPage}
          /> */}
          <Route exact component={ErrorPage} />
        </Switch>
      </Router>

      <FooterBar />
    </div>
  );
}

export default App;
