export interface probleminfoType {
  title: string;
  description: string;
  column_break: number;
  items: {
    title: string;
    description: string;
  }[];
}

export const probleminfo: probleminfoType = {
  title: "The Problem",
  description:
    "Technology in the Utility and Energy sector is evolving at an unprecedented rate.  Both large and small utilities alike find it difficult to wrap their heads around all of the details and expertise needed in order to be successful now and as they evolve into the future.",
  column_break: 3,
  items: [
    {
      title: "Choosing an AMI solution",
      description:
        "What problems are you trying to solve now?  The solution you decide to implement should not only support your immediate goals, but also have the ability to evolve for future known or unknown needs.",
    },
    {
      title: "Benefits",
      description:
        "Having the latest tech is excellent, but do you understand what the benefits are?  Knowing this is key to making the best decision for your needs.",
    },

    {
      title: "Mitigation Techniques",
      description:
        "Let’s be honest, not everything goes as planned.  With new tech and little experience implementing it, would you be confident in your ability to mitigate issues that arose during and after an implementation?",
    },
    {
      title: "Lack of Expertise",
      description:
        "Implementation of AMI solutions are complex, with many moving pieces.  Do you have the specific detailed knowledge to prepare, implement, manage and run it?",
    },
    {
      title: "Deployment",
      description:
        "At some point you have to deploy the solution and all of its components.  There will be a multitude of challenges that must be overcome both in preparation and in the field.  Do you know what those are and does your team have the experience required to help sidestep as many of those as possible from the start?",
    },
  ],
};
