import { probleminfoType } from "data/probleminfo";
import React from "react";
import { CardDeck, Col, Container, Row, Card, Image } from "react-bootstrap";

import problemicon from "assets/images/placeholder/100x100.png";

export const Problem = (probleminfo: probleminfoType) => {
  return (
    <div className="ap_problem">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto intro" md="11">
            <h1 className="title">{probleminfo.title}</h1>
            <div className="line"></div>
            <p className="description">{probleminfo.description}</p>
          </Col>
        </Row>

        <Row>
          <Col  md="11" className="ml-auto mr-auto problemlist">
            <Row>
              <Col  md="6" className="left">
                {probleminfo.items.map((v, i) => (
                  <>
                    {i >= 2 ? null : (
                      <Row>
                        <Col>
                          <h4> {v.title} </h4>
                          <p> {v.description} </p>
                        </Col>
                        {/* <Col className="ml-auto mr-auto">
                          <Image className="icon" src={problemicon} width={40} />
                        </Col> */}
                      </Row>
                    )}
                  </>
                ))}
              </Col>
              <Col md="6" className="right">
                {probleminfo.items.map((v, i) => (
                  <>
                    {i < 2 || i == 4 ? null : (
                      <Row>
                        <h4> {v.title} </h4>
                        <p> {v.description} </p>
                      </Row>
                    )}
                  </>
                ))}
              </Col>
            </Row>
            <Row>
              <div className="lastproblem">
                <h4> {probleminfo.items[4].title} </h4>
                <p> {probleminfo.items[4].description} </p>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
