import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import logo from "assets/images/branding/logo-transparent.png";
import backgroundImage from "assets/images/stockphotos/lr_exp/energypole-field.jpg";
import { ChevronDown } from "react-feather";
import { homepageinfoType } from "data/homepageinfo";

const backgroundStyle = {
  height: "100vh",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  // backgroundStyle:
};

export const Hero = (homepagedata: homepageinfoType) => {
  return (
    <div className="ap_hero" style={backgroundStyle} id="home">
      <Container>
        <Row>
          <Col className="" md="10">
            <div className="hero_info">
              <Row>
                <div className="mr-auto ml-5">
                  <Image src={logo} fluid />
                </div>
              </Row>
              <Row>
                <h1 className="title">{homepagedata.hero_title}</h1>
              </Row>
              <Row>
                <h5 className="description">{homepagedata.hero_subtitle}</h5>
              </Row>
            </div>
          </Col>
          <Col>{/* <Image src={logo} fluid /> */}</Col>
        </Row>
      </Container>
    </div>
  );
};
