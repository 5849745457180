import { contactinfoType } from "data/contactinfo";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

export const Contact = (contactdata: contactinfoType) => {
  return (
    <div className="ap_contact" id="contact">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Row>
              <h1 className="title"> Contact Us </h1>
            </Row>

            <Row>
              <div className="line"></div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <iframe
                src={`https://maps.google.com/maps?q=${contactdata.map.lat},${contactdata.map.long}&hl=en&z=10&output=embed`}
                title={"Contact Map"}
                style={{ width: "100%", height: "50vh", border: 0 }}
              />
            </div>
          </Col>
        </Row>
        <Row className="form_info">
          <Col className="ml-auto mr-auto" md="10">
            <Row>
              <Col className="questionaire" md="8">
                <Row>
                  <h2> Questions? </h2>
                  <p>{contactdata.inquiry_description}</p>
                </Row>

                <Row>
                  <Form
                    // onSubmit={}
                    id="contact_form"
                    action="https://formspree.io/f/mpzovwrk"
                    name="contact"
                    method="post"
                  >
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type={"name"}
                        id={"name"}
                        name={"name"}
                        placeholder={"John Smith"}
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type={"email"}
                        id={"email"}
                        name={"email"}
                        placeholder={"johnsmith@gmail.com"}
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        type={"subject"}
                        id={"subject"}
                        name={"subject"}
                        placeholder={"Business Inquiry"}
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      {/* <Form.Label>Message</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id={"message"}
                        name={"message"}
                        placeholder={"Message"}
                      />
                    </Form.Group>

                    {status === "SUCCESS" ? (
                      <p>Thanks!</p>
                    ) : (
                      <Button className="sb_button" type={"submit"}>
                        Send
                      </Button>
                    )}

                    {/* {status === "ERROR" && <p>Ooops! There was an error.</p>} */}
                  </Form>
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  <Col>
                    <Row>
                      <h3> Office Location </h3>
                    </Row>

                    <Row>
                      <p>{contactdata.office_location}</p>
                    </Row>

                    <Row>
                      <h3> Email </h3>
                    </Row>

                    <Row>
                      <p>{contactdata.email}</p>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
