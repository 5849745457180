export interface projectsinfoType {
  title: string;
  description: string;
  items: {
    company: string;
    logo_loc: string;
    description: string;
  }[];
}

export const projectsinfo: projectsinfoType = {
  title: "Past Project Involvement",
  description:
    "Our combined professional experience includes working on programs which include, but are not limited to the following clients:",
  items: [
    {
      company: "CenterPoint Energy",
      logo_loc: "centerpoint.png",
      description:
        "Multi-year AMI project consisting of 2.2 million electric meter replacement, 6,500 Data collection takeout point installations along with business process and mitigation creation and training.",
    },
    {
      company: "San Diego Gas and Electric",
      logo_loc: "sandyenergy.svg",
      description:
        "Multi-year AMI project consisting of 1.5 million electric meter replacement and 800,000 gas meter replacement as well as communication infrastructure deployment.",
    },
    {
      company: "Eversource Energy",
      logo_loc: "eversource.png",
      description:
        "Multi-year project consisting of replacement of over 500,000 analog electric meters to digital AMR meters throughout the entire state of New Hampshire.",
    },
    {
      company: "ConEdison",
      logo_loc: "coned.svg",
      description:
        "Multi-year project consisting of the replacement of over 5.5 million AMI electric and gas meters in New York, NY.",
    },
    {
      company: "Northwest Natural Gas",
      logo_loc: "nw_natural.png",
      description:
        "Multi-year project consisting of the replacement of over 700,000 legacy gas meters to AMR drive by technology.",
    },
    {
      company: "Southwest Gas",
      logo_loc: "SWGas.png",
      description:
        "Multi-year project consisting of the replacement of over 1.5 million legacy gas meters to AMR drive by technology and resource augmentation and integration support services.",
    },
    {
      company: "National Grid",
      logo_loc: "natgrid.svg",
      description:
        "Management services provided for strategic partnership to deploy an analytics platform used for testing with a pilot population in Boston, MA.",
    },
    {
      company: "Infinity Emission Solutions, Inc.",
      logo_loc: "infinity.png",
      description:
        "Strategic teaming to source Liquified Natural Gas (LNG) for distribution and export to the growing EMEA marketplace",
    },
  ],
};
