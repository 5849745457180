import React from "react";
import "assets/css/components/navbar.scss";
import { Container, Nav, Navbar, Row, Col, Image } from "react-bootstrap";
import logo from "assets/images/branding/1x/ap-logo.png";

export const NavigationBar = () => {
  return (
    <div>
      <Navbar bg="dark" variant="dark" className="nav_section" fixed="top">
        <Container fluid>
          <div className="brand">
            <Navbar.Brand href="/">
              <Image
                alt=""
                src={logo}
                height="40"
                className="d-inline-block"
              />
            </Navbar.Brand>
          </div>

          <Nav className="ml-auto links">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};
