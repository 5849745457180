import React from "react";
import {
  Container,
  Row,
  Col,
  CardColumns,
  Card,
  Image,
  ListGroup,
} from "react-bootstrap";
import aboutimage from "assets/images/stockphotos/shutterstock/SimpleWaterMeter.jpg";
import placeholder from "assets/images/placeholder/avatar.png";
import * as Icon from "react-feather";
import { AiFillLinkedin } from "react-icons/ai";
import { aboutinfoType } from "data/aboutinfo";

const aboutBackgroundStyle = {
  height: "auto",
  width: "auto",
  backgroundImage: `url(${aboutimage})`,
  backgroundSize: "auto 100vh",
  backgroundPosition: "150% 0%",
  backgroundAttachment: "fixed",

  // backgroundStyle:
};

export const AboutUs = (aboutdata: aboutinfoType) => {
  return (
    <div className="ap_aboutus" id="about">
      <Container fluid className="about_desc">
        <Row>
          <Col md="6">
            <Row>
              <Col className="ml-auto mr-5" md="6">
                <Row>
                  <h1 className="title">{aboutdata.title}</h1>
                </Row>
                <Row>
                  <div className="line"></div>
                </Row>
                <Row>
                  <h3 className="title">{aboutdata.subtitle}</h3>
                  {aboutdata.description.map((v) => (
                    <p>{v}</p>
                  ))}
                  <div className="end" />

                  {/* {aboutdata.description.map((v, i) => (
                    <p>
                      {" "}
                      {i}
                      {v}{" "}
                    </p>
                  ))} */}
                </Row>
              </Col>
            </Row>
          </Col>

          <Col className="about_image_col" style={aboutBackgroundStyle}></Col>
        </Row>
      </Container>

      {/* Stats */}

      <Container className="about_stats" fluid>
        <Col className="mr-auto ml-auto" md="8">
          <Row>
            {aboutdata.stats.map((v, i) => (
              <>
                <Col>
                  <Row>
                    <Col>
                      <h1> {v.value} </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3> {v.description} </h3>
                    </Col>
                  </Row>
                </Col>

                {i < aboutdata.stats.length - 1 ? (
                  <>
                    <Col md="1" className="line_container">
                      <div className="horizontal_line" />
                    </Col>
                  </>
                ) : null}
              </>
            ))}
          </Row>
        </Col>
      </Container>

      {/* The Team */}
      {/* Turn off for now */}
      {/* 
      <Container className="about_team">
        <Row className="people_cards">
          <Col className="ml-auto mr-auto" md="10">
            <Row>
              <Col>
                <h1 className="title">The Team</h1>
                <div className="line"></div>
              </Col>
            </Row>

            <Row className="persons">
              <Col md={"4"} className="person_card">
                <Row>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Image roundedCircle fluid src={placeholder} />
                  </Col>
                </Row>

                <Row>
                  <h4 className="card_title">John Smith</h4>
                </Row>

                <Row>
                  <h6 className="card_subtitle">subtitle</h6>
                </Row>
                <Row className="ml-auto mr-auto">
                  <Col>
                    <p>
                      I am john smith Lorem, ipsum dolor sit amet consectetur
                      adipisicing elit. In voluptas quo, at quas consequuntur
                      ratione ex distinctio.
                    </p>
                  </Col>
                </Row>
                <Row className="social">
                  <Col className="ml-auto mr-auto">
                    <AiFillLinkedin size="2rem" />
                  </Col>
                </Row>
              </Col>

              <Col md={"4"} className="person_card">
                <Row>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Image roundedCircle fluid src={placeholder} />
                  </Col>
                </Row>

                <Row>
                  <h4 className="card_title">John Smith</h4>
                </Row>

                <Row>
                  <h6 className="card_subtitle">subtitle</h6>
                </Row>
                <Row className="ml-auto mr-auto">
                  <Col>
                    <p>
                      I am john smith Lorem, ipsum dolor sit amet consectetur
                      adipisicing elit. In voluptas quo, at quas consequuntur
                      ratione ex distinctio.
                    </p>
                  </Col>
                </Row>
                <Row className="social">
                  <Col className="ml-auto mr-auto">
                    <AiFillLinkedin size="2rem" />
                  </Col>
                </Row>
              </Col>

              <Col md={"4"} className="person_card">
                <Row>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Image roundedCircle fluid src={placeholder} />
                  </Col>
                </Row>

                <Row>
                  <h4 className="card_title">John Smith</h4>
                </Row>

                <Row>
                  <h6 className="card_subtitle">subtitle</h6>
                </Row>
                <Row className="ml-auto mr-auto">
                  <Col>
                    <p>
                      I am john smith Lorem, ipsum dolor sit amet consectetur
                      adipisicing elit. In voluptas quo, at quas consequuntur
                      ratione ex distinctio.
                    </p>
                  </Col>
                </Row>
                <Row className="social">
                  <Col className="ml-auto mr-auto">
                    <AiFillLinkedin size="2rem" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
     */}
    </div>
  );
};
