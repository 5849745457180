import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardGroup,
  CardDeck,
  CardColumns,
  Table,
  Image,
  Carousel,
} from "react-bootstrap";

import placeholder from "assets/images/placeholder/100x100.png";
import contactimage from "assets/images/stockphotos/shutterstock/Business.jpg";

import austinwater from "assets/images/clients/austinwater.png";
import centerpoint from "assets/images/clients/centerpoint.png";
import SWGAS from "assets/images/clients/SWGas.png";
import natgrid from "assets/images/clients/natgrid.svg";
import LA_Water from "assets/images/clients/LA_Water.jpg";
import eversource from "assets/images/clients/eversource.png";
import nwnatural from "assets/images/clients/nw_natural.png";
import DallasWater from "assets/images/clients/DallasWater.png";
import sandy from "assets/images/clients/sandyenergy.svg";
import coned from "assets/images/clients/coned.svg";
import { projectsinfoType } from "data/projectsinfo";

const aboutBackgroundStyle = {
  // width: "50%",
  //height: "auto",
  backgroundImage: `url(${contactimage})`,
  backgroundSize: "auto 100%",
  backgroundPosition: "center left",
  backgroundAttachment: "fixed",

  // backgroundStyle:
};

const settings = {
  dots: true,
  slidesToShow: 3,
};

export const Clients = (projectsdata: projectsinfoType) => {
  return (
    <div className="ap_client">
      <Container fluid>
        <Row>
          <Col className="about_image_col" style={aboutBackgroundStyle}></Col>
          <Col md="6" className="client_text">
            <Row className="client_desc">
              <Col className="mr-auto ml-auto" md="8">
                <Row>
                  <h1 className="title">{projectsdata.title}</h1>
                </Row>
                <Row>
                  <div className="line"></div>
                </Row>
                <Row>
                  <p>{projectsdata.description}</p>
                </Row>
              </Col>
            </Row>

            <Row className="client_example">
              <Col className="mr-auto ml-5" md="10">
                <Carousel indicators={false}>
                  {projectsdata.items.map((v) => (
                    <Carousel.Item>
                      <Container>
                        <Row className="block">
                          <Col md="5" className="mr-auto ml-auto img_div">
                            <Image
                              src={
                                require(`assets/images/clients/${v.logo_loc}`)
                                  .default
                              }
                              
                            />
                          </Col>

                          <Col md="6" className="mr-auto ml-auto">
                            <Row>
                              <h1> {v.company}</h1>
                            </Row>
                            <Row>
                              <p> {v.description}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Col>

          {/* <Col className="about_image_col" style={aboutBackgroundStyle}></Col> */}
        </Row>
      </Container>
    </div>
  );
};
