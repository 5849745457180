import { Hero } from "containers/Home/Hero";
import { ServiceExp } from "containers/Home/ServiceExp";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import "assets/css/pages/Home.scss";
import { AboutUs } from "containers/Home/AboutUs";
import { Clients } from "containers/Home/Clients";
import { Contact } from "containers/Home/Contact";
import {
  getAboutData,
  getContactData,
  getHomepageData,
  getProblemData,
  getProjectsData,
  getServiceData,
} from "utils/dataManager";
import { Problem } from "containers/Home/Problem";

export const HomePage = () => {
  const homepagedata = getHomepageData();
  const servicepagedata = getServiceData();
  const aboutpagedata = getAboutData();
  const problempagedata = getProblemData();
  const contactpagedata = getContactData();
  const projectsinfodata = getProjectsData();

  return (
    <div>
      <Hero {...homepagedata} />

      {/* The problem page */}

      <Problem {...problempagedata} />

      <ServiceExp {...servicepagedata} />

      {/* Fields? */}
      

      <AboutUs {...aboutpagedata} />

      <Clients {...projectsinfodata}/>

      <Contact {...contactpagedata} />
    </div>
  );
};
