export interface aboutinfoType {
  title: string;
  subtitle: string;
  description: string[];
  stats: {
    value: string;
    description: string;
  }[];
}

export const aboutinfo: aboutinfoType = {
  title: "About",
  subtitle: "Precision Allied",
  description: [
    "Precision Allied Services is an Advanced Metering Infrastructure (AMI) consulting company comprised of a highly skilled team of industry experts with over 30 years of combined AMI industry experience and the deployment of over 8 million meters throughout the United States and Canada.",
    "We have experience leading nearly all aspects of AMI programs for some of the largest water, gas, and electric utility companies nationwide. Not only have we helped these companies deploy meters and communication networks, but we have also provided the necessary business, analytic and operational guidance that helps the utility leverage the benefits of the AMI solution.",
  ],
  stats: [
    {
      value: "2012",
      description: "Year Established",
    },
  ],
};
